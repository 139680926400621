/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "ibizahighlife-20210602195726-hostingbucket-master",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://ibizahighlife-20210602195726-hostingbucket-master.s3-website-eu-west-1.amazonaws.com",
    "aws_cloud_logic_custom": [
        {
            "name": "ibizahighlifeapi",
            "endpoint": "https://sxvls3nwxi.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
