import * as React from "react"
import PropTypes from "prop-types"

import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

import { GiCook, GiForkKnifeSpoon } from "react-icons/gi"
import { FaUmbrellaBeach } from "react-icons/fa"
import {
  MenuIcon,
  PhoneIcon,
  XIcon,
  SearchCircleIcon,
  ViewListIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline"

import Spa from "../assets/icons/spa.svg"

import { ReactSVG } from "react-svg"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const conciergeMenu = [
  {
    name: "Transfers",
    href: "/transfers",
    description: "Ibiza VIP Chauffeurs",
    icon: ArrowRightIcon,
  },
  {
    name: "In-House Catering",
    href: "/concierge/chef-in-house-catering/",
    description: "Chefs in your Villa",
    icon: GiCook,
  },
  {
    name: "View all Services",
    href: "/concierge",
    // description: "IBIZA VIP CHAUFFEURS",
    icon: ViewListIcon,
    backgroundColor: "#/f5f5f5",
  },
]
const callsToAction = [
  { name: "Search Villas", href: "/villas", icon: SearchCircleIcon },
  // { name: "Talk to Us", href: "#/", icon: PhoneIcon },
]

const contactDetails = {
  phone: "+447376390622",
  email: "info@ibizahighlife.com",
}

const guides = [
  {
    name: "What's Open Now",
    description: "A live list of what's open 2021",
    href: "/whats-open",
    icon: GiForkKnifeSpoon,
    backgroundColor: "#f5f5f5",
  },
  {
    name: "Restaurants",
    description: "The definitive guide to the best restaurants around Ibiza",
    href: "/reservations/restaurants",
    icon: GiForkKnifeSpoon,
  },
  {
    name: "Beach Clubs",
    description: "Visit iconic Ibiza beach clubs",
    href: "/reservations/ibiza-beach-clubs",
    icon: FaUmbrellaBeach,
  },
  {
    name: "Wellness Spas",
    description: "A Comprehensive Guide Ibiza's Spas",
    href: "/ibiza-spas",
    // icon: FaUmbrellaBeach,
    iconPath: "/icon/spa.svg",
  },
  {
    name: "Clubs & Nightlife",
    description: "World-renowned Ibiza Clubbing",
    href: "/reservations/ibiza-clubs",
    icon: FaUmbrellaBeach,
  },
  {
    name: "Beaches",
    description: "Check out our Beach Guide",
    href: "/beaches",
    icon: FaUmbrellaBeach,
  },
]
const recentPosts = [
  { id: 1, name: "The Best Spas in Ibiza", href: "/the-best-spas-on-ibiza" },
  {
    id: 2,
    name: "Ibiza's Best Beach Restaurants",
    href: "/ibiza-best-beach-restaurants",
  },
  // { id: 3, name: "The Ultimate Ibiza Luxury Guide", href: "#" },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Header = ({ siteTitle }) => (
  <div className="relative font-serif z-50">
    <div className="bg-gray-100">
      <div className="grid gap-1 sm:gap-0 space-x-0 sm:flex justify-center py-2 text-xs sm:text-sm sm:space-x-4  text-gray-600">
        <span>
          e:{" "}
          <a href={"mailto:" + contactDetails.email}>{contactDetails.email} </a>
        </span>
        <span>
          p: <a href={"tel:" + contactDetails.phone}>{contactDetails.phone} </a>
        </span>
      </div>
    </div>
    <Popover className="relative w-full mx-auto ">
      {({ open }) => (
        <>
          <div className="container mx-auto py-4 px-2 lg:px-8">
            <div className=" flex justify-between items-center py-2">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/">
                  <span className="sr-only">Ibiza High Life</span>
                  <StaticImage
                    height={45}
                    className="md:h-10  w-auto h-12"
                    src="../../static/img/logo.svg"
                    alt="Ibiza High Life"
                  />
                </Link>
              </div>

              <Popover.Group>
                <div className="flex flex-col justify-between items-center">
                  <div className="hidden md:flex md:space-x-10">
                    <a
                      href="/villas"
                      className="text-base font-medium text-black hover:text-gray-900 uppercase"
                    >
                      Villas
                    </a>
                    <a
                      href="/boats"
                      className="text-base font-medium text-black hover:text-gray-900 uppercase"
                    >
                      Boats
                    </a>
                    <a
                      href="/cars"
                      className="text-base font-medium text-black hover:text-gray-900 uppercase"
                    >
                      Cars
                    </a>
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-gray-900" : "text-black",
                              "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none outline-none"
                            )}
                          >
                            <span className="uppercase">The Concierge</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-600" : "text-gray-400",
                                "ml-2 h-5 w-5 group-hover:text-black"
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              static
                              className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                            >
                              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  {conciergeMenu.map(item => (
                                    <a
                                      key={item.name}
                                      href={item.href}
                                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                      style={{
                                        backgroundColor: item.backgroundColor,
                                      }}
                                    >
                                      {item.icon && (
                                        <item.icon
                                          className="flex-shrink-0 h-6 w-6 text-gray-700"
                                          aria-hidden="true"
                                        />
                                      )}
                                      <div className="ml-4">
                                        <p className="text-base font-medium text-gray-900 uppercase">
                                          {item.name}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-400 font-sans">
                                          {item.description}
                                        </p>
                                      </div>
                                    </a>
                                  ))}
                                </div>
                                <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                  {callsToAction.map(item => (
                                    <div key={item.name} className="flow-root">
                                      <a
                                        href={item.href}
                                        className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                      >
                                        {item.icon && (
                                          <item.icon
                                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        )}
                                        <span className="ml-3">
                                          {item.name}
                                        </span>
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                    {/* <a
                  href="#/"
                  className="text-base font-medium text-black hover:text-gray-900"
                >
                  Boats
                </a>
                <a
                  href="#/"
                  className="text-base font-medium text-black hover:text-gray-900"
                >
                  Cars
                </a> */}
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-gray-900" : "text-black",
                              "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 outline-none focus:outline-none"
                            )}
                          >
                            <span className="uppercase">Guide</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-600" : "text-gray-400",
                                "ml-2 h-5 w-5 group-hover:text-black"
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              static
                              className="absolute right-0 z-10 transform mt-3 px-2 w-screen max-w-sm sm:px-0 mr-0 ml-auto"
                            >
                              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  {guides.map(item => (
                                    <a
                                      key={item.name}
                                      href={item.href}
                                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                      style={{
                                        backgroundColor: item.backgroundColor,
                                      }}
                                    >
                                      {/* {item.icon && (
                                        <item.icon
                                          className="flex-shrink-0 h-6 w-6 text-gray-700"
                                          aria-hidden="true"
                                        />
                                      )} */}
                                      {/* <ReactSVG
                                        src="/icon/spa.svg"
                                        className="flex-shrink-0 h-6 w-6 text-gray-700"
                                      /> */}
                                      <div className="ml-4">
                                        <p className="text-base font-medium text-gray-900 uppercase">
                                          {item.name}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-400 font-sans">
                                          {item.description}
                                        </p>
                                      </div>
                                    </a>
                                  ))}
                                </div>
                                <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                                  <div>
                                    <h3 className="text-sm tracking-wide font-medium text-black uppercase">
                                      Recent Blogs
                                    </h3>
                                    <ul className="mt-4 space-y-4">
                                      {recentPosts.map(item => (
                                        <li
                                          key={item.id}
                                          className="text-base truncate"
                                        >
                                          <a
                                            href={item.href}
                                            className="font-medium text-gray-900 hover:text-gray-700"
                                          >
                                            {item.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                  {/* <div className="mt-5 text-sm">
                                    <a
                                      href="#/"
                                      className="font-medium text-gray-600 hover:text-gray-500"
                                    >
                                      {" "}
                                      View all posts{" "}
                                      <span aria-hidden="true">&rarr;</span>
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                </div>
              </Popover.Group>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>

              {/* <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <a
                  href="#/"
                  className="whitespace-nowrap text-base font-medium text-black hover:text-gray-900"
                >
                  Sign in
                </a>
                <a
                  href="#/"
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-600 hover:bg-gray-700"
                >
                  Sign up
                </a>
              </div> */}
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <StaticImage
                        className="h-10 w-auto"
                        height={45}
                        src="../../static/img/logo.svg"
                        alt="Ibiza High Life"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid gap-y-8">
                      {conciergeMenu.map(item => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        >
                          {item.icon && (
                            <item.icon
                              className="flex-shrink-0 h-6 w-6 text-gray-600 uppercase"
                              aria-hidden="true"
                            />
                          )}
                          <span className="ml-3 text-base text-gray-400 font-sans">
                            {item.name}
                          </span>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                    <a
                      href="#/"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Pricing
                    </a>

                    <a
                      href="#/"
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Docs
                    </a>
                    {guides.map(item => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div>
                    <a
                      href="#/"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-600 hover:bg-gray-700"
                    >
                      Sign up
                    </a>
                    <p className="mt-6 text-center text-base font-medium text-black">
                      Existing customer?
                      <a
                        href="#/"
                        className="text-gray-600 hover:text-gray-500"
                      >
                        Sign in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
